<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><i class="ri-edit-line"></i> {{ $route.query.lc_no ? $t('movement.c_and_f_agent_appointment_management') + ' ' + $t('globalTrans.update') : $t('movement.c_and_f_agent_appointment_management') + ' ' + $t('globalTrans.entry')}}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12" class="form-for-textarea">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-data">
                    <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="LC No"  vid="lc_no" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="lc_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('procurement.lc_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="lc_no"
                                  v-model="lc_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                              <div class="invalid-feedback d-block" v-if="lcErrorMsg">
                                {{ lcErrorMsg }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="vessels_name"
                          >
                            <template v-slot:label>
                              {{$t('movement.vesselsName')}}
                            </template>
                            <b-form-input
                                id="vessels_name"
                                v-model="formData.ship_name"
                                v-if="currentLocale === 'en'"
                                readonly
                            ></b-form-input>
                            <b-form-input
                                id="vessels_name"
                                v-model="formData.ship_name_bn"
                                v-else
                                readonly
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="country_name"
                          >
                            <template v-slot:label>
                              {{$t('movement.sourceCountry')}}
                            </template>
                            <b-form-input
                                id="country_name"
                                v-model="formData.country_name"
                                v-if="currentLocale === 'en'"
                                readonly
                            ></b-form-input>
                            <b-form-input
                                id="country_name"
                                v-model="formData.country_name_bn"
                                v-else
                                readonly
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Fertilizer Name"  vid="fertilizer_name" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fertilizer_name"
                            >
                              <template v-slot:label>
                                {{$t('movement.fertilizerName')}}
                              </template>
                              <b-form-input
                                  id="fertilizer_name"
                                  v-model="formData.fertilizer_name"
                                  v-if="currentLocale === 'en'"
                                  readonly
                              ></b-form-input>
                              <b-form-input
                                  id="fertilizer_name"
                                  v-model="formData.fertilizer_name_bn"
                                  v-else
                                  readonly
                              ></b-form-input>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Memo No"  vid="memo_no" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="memo_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('movement.memoNo')}}  <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="memo_no"
                                  v-model="formData.memo_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Date"  vid="date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('globalTrans.date') }}  <span class="text-danger">*</span>
                              </template>
                              <flat-pickr class="form-control"
                                  id="date"
                                  :placeholder="$t('globalTrans.select_date')"
                                  v-model="formData.date"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></flat-pickr>
                              <div class="invalid-feedback d-block" v-if="errors.length">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-overlay :show="loading">
                      <b-container style="min-height:150px" v-if='formData.port_allocation_ports'>
                        <b-row class="mt-3" v-for="(port, index1) in formData.port_allocation_ports" :key="index1">
                          <b-col class="text-center">
                            <p class="head">{{ $t('movement.port') }} : {{ $i18n.locale === 'bn' ? port.port.port_name_bn : port.port.port_name }}</p>
                            <p class="head">{{ $t('movement.allocated_amount') }} : {{ port.allocated_amount }}</p>
                          </b-col>
                          <b-table-simple class="mt-3" hover bordered small caption-top responsive>
                            <b-thead head-variant="secondary">
                              <b-tr>
                                <b-td class="text-center">{{ $t('globalTrans.sl_no') }}</b-td>
                                <b-td>{{ $t('movement.c_and_f_agent') }}</b-td>
                                <b-td>{{ $t('movement.allocated_amount') }}</b-td>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <b-tr v-for="(agent, index) in port.details" :key="index">
                                <b-td class="text-center">{{ $n(index+1) }}</b-td>
                                <b-td>{{ currentLocale === 'en' ? agent.company_agent_name : agent.company_agent_name_bn }}</b-td>
                                <b-td class="text-right">
                                  <b-form-group
                                  >
                                    <b-form-input
                                        @keyup="calCulateAmount(port, index, index1)"
                                        type="number"
                                        v-model="agent.quantity"
                                        small
                                    ></b-form-input>
                                  </b-form-group>
                                </b-td>
                              </b-tr>
                            </b-tbody>
                            <b-tfoot>
                              <b-tr variant="" >
                                <b-th colspan="2" class="text-right">
                                  {{ $t('sitePreference.total') }}:
                                </b-th>
                                <th class="text-right">{{ port.allocated_amount }}</th>
                              </b-tr>
                              <b-tr variant="">
                                <b-th colspan="2" class="text-right">
                                  {{ $t('movement.availableAmount') }}:
                                </b-th>
                                <th class="text-right">{{ getSettAmount(port) }}</th>
                              </b-tr>
                            </b-tfoot>
                          </b-table-simple>
                        </b-row>
                      </b-container>
                      <div class="row mt-2">
                        <div class='col-md-4 offset-md-4'>
                            <b-button type="submit" variant="primary" class="mr-2 btn-block">{{ saveBtnName }}</b-button>
                        </div>
                      </div>
                    </b-overlay>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { storeCandFinfo, getCandFinfo, checkLcNo } from '../../api/routes'

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.$route.query.lc_no ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        port_allocation_ports: []
      },
      totalAllocatedAmount: 0,
      totalAvailableAmount: 0,
      lcErrorMsg: '',
      lc_no: '',
      loading: false,
      lastData: 0
    }
  },
  created () {
    if (this.$route.query.lc_no) {
      this.lc_no = this.$route.query.lc_no
    }
  },
  computed: {
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  watch: {
    lc_no: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (!this.$route.query.lc_no) {
          this.checkLC(newVal)
        } else {
          this.getLcInfo(newVal)
        }
      }
    }
  },
  methods: {
    async createData () {
      this.loading = true
      let result = null
      result = await RestApi.postData(seedFertilizerServiceBaseUrl, storeCandFinfo, this.formData)
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.$route.query.lc_no ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.$router.push('c-and-f-agent-appointment-management')
    },
    async checkLC (lcNo) {
      if (lcNo) {
        this.loading = true
        const resultLc = await RestApi.postData(seedFertilizerServiceBaseUrl, checkLcNo, { lc_no: lcNo })
        if (resultLc.success === false) {
          this.$refs.form.setErrors(resultLc.errors)
          this.loading = false
        } else {
          this.getLcInfo(lcNo)
        }
      }
    },
    async getLcInfo (lcNo) {
      if (lcNo) {
        this.loading = true
        const result = await RestApi.getData(seedFertilizerServiceBaseUrl, getCandFinfo, { lc_no: lcNo })
        if (result.success) {
          Object.assign(this.formData, result.data)
        } else {
          this.formData = {
            port_allocation_ports: []
          }
        }
        this.loading = false
      }
    },
    getSettAmount (val) {
      if (val.details.length > 0) {
        const allocatedAmount = val.allocated_amount
        const totalset = val.details.map(o => o.quantity).reduce((a, c) => {
        a = a ? parseInt(a) : 0
        c = c ? parseInt(c) : 0
          return a + c
        })
        return (allocatedAmount - totalset < 0) ? 0 : allocatedAmount - totalset
      }
    },
    calCulateAmount (val, index, index1) {
      const allocatedAmount = val.allocated_amount
      const totalset = val.details.map(o => o.quantity).reduce((a, c) => {
        a = a ? parseInt(a) : 0
        c = c ? parseInt(c) : 0
        return a + c
      })
      if (allocatedAmount < totalset) {
        const currentAmt = totalset - this.formData.port_allocation_ports[index1].details[index].quantity
        const availableAmount = (allocatedAmount - currentAmt) < 0 ? 0 : allocatedAmount - currentAmt
        // const quantity = totalset.toString().substr(0, length.length)
        this.formData.port_allocation_ports[index1].details[index].quantity = availableAmount
      }
    }
  }
}
</script>
<style scope>
  .head {
    font-size: 14px;
    font-weight: 700;
    color: #655f5f;
    margin-bottom: 0 !important;
  }
</style>
